const config = {
    baseURL: 'https://yourtoken.art:2053',
    chatURL: 'https://yourtoken.art:2053'
};

// const config = {
//     baseURL: 'http://37.48.109.16:2053',
//     chatURL: 'http://37.48.109.16:2053'
// };

// const config = {
//     baseURL: 'http://127.0.0.1:4001',
//     chatURL: 'http://127.0.0.1:4001'
// };

export default config;