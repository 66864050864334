import React, {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react';
import {ethers} from 'ethers'
import axios from 'axios'
import {ArtData} from "@/database/art"
import {VoteData} from "@/database/vote"
import {Admin} from "@/database/admin"
import {Transaction} from "@/database/transaction"
import {createWeb3Modal, defaultConfig, useWeb3ModalAccount, useWeb3ModalProvider} from '@web3modal/ethers/react'
import serverConfig from "@/server-config"
import {Image} from "@/database/image";
import NFT from "../abis/NFT.json"
import blockchainConfig from "../config.json"
import genericErc20Abi from "../abis/Erc20.json"
import {ArtState, TransactionActivity, TransactionStatus, TransactionType} from "@/database/types";
import {ClaimData} from "@/database/claim";
import _ from "lodash";

const tokenContractAddress = '0x8a810ea8B121d08342E9e7696f4a9915cBE494B7' // PLSX testnet
const noNukesContractAddress = '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39'
const custodial = '0x6BA1bFBED256b8A9245C6332dC6e0F51D6ca1053'
const projectId = '0615b90f13719639d67f15ee7b7f9289'

const mainNetwork = {
    chainId: 943,
    name: 'Pulsechain V4 Test Network',
    currency: 'tPLS',
    explorerUrl: 'https://scan.v4.testnet.pulsechain.com',
    rpcUrl: 'https://rpc.v4.testnet.pulsechain.com'
}

const metadata = {
    name: 'YourToken.Art',
    description: 'YourToken.Art CRAZY Finance NFT Marketplace',
    url: 'https://yourtoken.art', // origin must match your domain & subdomain
    icons: ['https://yourtoken.art/']
}

const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 1, // used for the Coinbase SDK
})

// Web3Modal instance
createWeb3Modal({
    ethersConfig,
    chains: [mainNetwork],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
})

export interface UserShare {
    creator: number,
    owner: number,
    votes: number,
    claims: number,
    airdrop: number
}

export interface Tokenomics {
    artState: ArtState
    fee: number,
    owner: number,
    creator: number,
    airdrop: number,
    votes: number,
    claims: number,
    marketing: number,
    burn: number,
    burnToken: string,
}

export interface ChatMessage {
    id: number
    text: string
    name: string
    messageId: string
    timestamp: number
    socketId: string
    tags: string[]
}

export enum UpdateType {
    VOTES = 'VOTES',
    CLAIMS = 'CLAIMS',
    ADMIN = 'ADMIN',
    MARKETS = 'MARKETS',
    PROPOSALS = 'PROPOSALS',
    AUCTIONS = 'AUCTIONS',
    PORTFOLIO = 'PORTFOLIO',
    ART = 'ART'
}

export enum UpdateSpecificType {
    TRANSACTION_USER = 'TRANSACTION_USER',
    TRANSACTION_TOKEN = 'TRANSACTION_TOKEN',
    ART = 'ART'
}

export interface UpdateSpecific {
    type: UpdateSpecificType
    id: number | string
}

export interface SpecificUpdate {
    updates: UpdateSpecific[]
}

export interface Filters {
    forSale: boolean
    offerings: boolean
    marketMyArt: boolean
    marketMyClaims: boolean
    marketMyOffers: boolean
    auctionMyArt: boolean
    auctionMyClaims: boolean
    auctionMyBids: boolean
    forced: boolean
    activeVote: boolean
    created: boolean
    owned: boolean
    hidden: boolean
}

export type ContextType = {
    acceptBid: (artData: ArtData) => Promise<void>
    acceptOffer: (artData: ArtData) => Promise<void>
    adminConfiguration: Admin
    artDataList: ArtData[]
    auctionDataList: ArtData[]
    accountSignature: string
    bidHandler: (artData: ArtData, bidValue: number, previousBid: number) => Promise<void>
    categories: string[]
    checkWalletConnection: () => Promise<void>
    claimedAmountTable: Record<number, number>
    claimedDataList: ClaimData[],
    claimHandler: (castingArtId: number, artData: ArtData, claimValue: number) => Promise<void>
    connectWallet: () => Promise<void>
    crazyPrice: string
    createNFT: (url: string, unformattedPrice: string, id: number, resell: boolean) => Promise<void>
    currentAccount: string
    disconnectWallet: () => Promise<void>
    endAuction: (tokenId: number) => Promise<void>
    faucetHandler: () => Promise<void>
    fetchImage: (imageId: number) => Promise<Image>
    filters: Filters
    getCachedImage: (imageId: number) => Image | undefined
    getTokenomics: (artData: ArtData) => Tokenomics
    hideContent: boolean
    loadNoNukeAmount: () => Promise<void>
    loadPulseAmount: () => Promise<void>
    loadTokenAmount: () => Promise<void>
    loading: boolean
    logIn: boolean
    messages: ChatMessage[]
    noNukeAmount: string
    offerHandler: (artData: ArtData, amount: number, expiration: number) => Promise<void>
    portfolioClaimTable: Record<number, number>
    portfolioData: ArtData[]
    proposeHandler: (artData: ArtData) => Promise<void>
    proposalData: ArtData[]
    pulseAmount: string
    pulsePrice: string
    remove: (artId: number) => Promise<void>
    saleHandler: (artData: ArtData, amount: string, expiration: number) => Promise<void>
    setActiveUpdates: Dispatch<SetStateAction<UpdateType[]>>
    setHideContent: React.Dispatch<React.SetStateAction<boolean>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setActiveSpecificUpdates: Dispatch<SetStateAction<SpecificUpdate>>
    socket: any
    socketId: string
    tokenAmount: string
    toggleHideArt: (artData: ArtData) => Promise<void>
    toggleFilter: (filter: keyof Filters) => void
    totalClaimPower: string,
    transactionTokenArray: Transaction[][],
    transactionUserArray: Transaction[][],
    typingStatus: string,
    voteHandler: (artData: ArtData, voteValue: number) => Promise<void>
    votePower: string
    votedDataList: VoteData[]
};

// Create the context with default values
export const Context = createContext<ContextType>({
    acceptBid: () => Promise.resolve(),
    acceptOffer: () => Promise.resolve(),
    adminConfiguration: {} as Admin,
    artDataList: [] as ArtData[],
    auctionDataList: [] as ArtData[],
    accountSignature: '',
    bidHandler: () => Promise.resolve(),
    categories: [],
    checkWalletConnection: () => Promise.resolve(),
    claimedAmountTable: {} as Record<number, number>,
    claimedDataList: [] as ClaimData[],
    claimHandler: () => Promise.resolve(),
    connectWallet: () => Promise.resolve(),
    crazyPrice: ' - ',
    createNFT: () => Promise.resolve(),
    currentAccount: '',
    disconnectWallet: () => Promise.resolve(),
    endAuction: () => Promise.resolve(),
    faucetHandler: () => Promise.resolve(),
    fetchImage: () => Promise.resolve({} as Image),
    filters: {} as Filters,
    getCachedImage: () => undefined,
    getTokenomics: (): Tokenomics => {
        return {} as Tokenomics
    },
    hideContent: false,
    loadNoNukeAmount: () => Promise.resolve(),
    loadPulseAmount: () => Promise.resolve(),
    loadTokenAmount: () => Promise.resolve(),
    loading: false,
    logIn: false,
    messages: [] as ChatMessage[],
    noNukeAmount: ' - ',
    offerHandler: () => Promise.resolve(),
    portfolioClaimTable: {} as Record<number, number>,
    portfolioData: [] as ArtData[],
    proposeHandler: () => Promise.resolve(),
    proposalData: [] as ArtData[],
    pulseAmount: ' - ',
    pulsePrice: ' - ',
    remove: () => Promise.resolve(),
    saleHandler: () => Promise.resolve(),
    setActiveUpdates: {} as Dispatch<SetStateAction<UpdateType[]>>,
    setHideContent: () => {
    },
    setLoading: () => {
    },
    setActiveSpecificUpdates: {} as Dispatch<SetStateAction<SpecificUpdate>>,
    socket: '',
    socketId: '',
    tokenAmount: '',
    toggleHideArt: () => Promise.resolve(),
    toggleFilter: () => {
    },
    transactionTokenArray: [],
    transactionUserArray: [],
    typingStatus: '',
    totalClaimPower: '-',
    voteHandler: () => Promise.resolve(),
    votePower: '-',
    votedDataList: [] as VoteData[],
});

let ws: WebSocket;

// Contract interaction
const fetchContract = (signerOrProvider: any) =>
    new ethers.Contract(tokenContractAddress, genericErc20Abi, signerOrProvider);


const generateClaimedAmountTable = (claimedDataArray: ClaimData[]): Record<number, number> =>
    claimedDataArray.reduce((record, claimData) => {
        const tokenId = claimData.targetArt.tokenId;
        const amount = parseInt(claimData.claim.amount);
        record[tokenId] = (record[tokenId] || 0) + amount;
        return record;
    }, {} as Record<number, number>);

const generatePortfolioClaimTable = (portfolioData: ArtData[]): Record<number, number> =>
    portfolioData.reduce((record, artData) => {
        const artId = artData.art.id;
        record[artId] = (record[artId] || 0) + artData.claimPower - artData.totalClaims;
        return record;
    }, {} as Record<number, number>);

const calcTotalVotedAmount = (voteDataArray: VoteData[]): number => {
    return voteDataArray.reduce((total: number, voteData) => {
        if ((voteData.art.state === ArtState.FORCED_AUCTION || voteData.art.state === ArtState.CREATED)) {
            const amount = parseFloat(voteData.vote.amount ? voteData.vote.amount : "0");
            total += amount;
        }
        return total;
    }, 0);
}

const ContextProvider = ({children}) => {
    const storedFilters = typeof window !== "undefined" && localStorage.getItem("filters")

    const [adminConfiguration, setAdminConfiguration] = useState({} as Admin);
    const [categories, setCategories] = useState([])
    const [currentAccount, setCurrentAccount] = useState('');
    const [accountSignature, setAccountSignature] = useState('');
    const [tokenAmount, setTokenAmount] = useState('-');
    const [noNukeAmount, setNoNukeAmount] = useState('-');
    const [pulseAmount, setPulseAmount] = useState('-');
    const [votePower, setVotePower] = useState('-');
    const [totalClaimPower, setTotalClaimPower] = useState('-');
    const [loading, setLoading] = useState(false);
    const [logIn, setLogIn] = useState(false);
    const [hideContent, setHideContent] = useState(false);
    const [portfolioData, setPortfolioData] = useState<ArtData[]>([]);
    const [proposalData, setProposalData] = useState<ArtData[]>([]);
    const [artDataList, setArtDataList] = useState<ArtData[]>([]);
    const [imageList, setImageList] = useState<Image[]>([]);
    const [auctionDataList, setAuctionDataList] = useState<ArtData[]>([]);
    const [votedDataList, setVotedDataList] = useState<VoteData[]>([]);
    const [claimedDataList, setClaimedDataList] = useState<ClaimData[]>([]);
    const [claimedAmountTable, setClaimedAmountTable] = useState<Record<number, number>>({}); // Lookup table for finding the total claim amount used per asset
    const [crazyPrice, setCrazyPrice] = useState(" - ")
    const [portfolioClaimTable, setPortfolioClaimTable] = useState<Record<number, number>>({});
    const {isConnected} = useWeb3ModalAccount()
    const {walletProvider} = useWeb3ModalProvider()
    const [messages, setMessages] = useState([] as ChatMessage[])
    const [transactionTokenArray, setTransactionTokenArray] = useState<Transaction[][]>([]);
    const [transactionUserArray, setTransactionUserArray] = useState<Transaction[][]>([]);
    const [pulsePrice, setPulsePrice] = useState(" - ")
    const [typingStatus, setTypingStatus] = useState("")
    const [socket, setSocket] = useState<WebSocket | null>(null)
    const [socketId, setSocketId] = useState<string>("0")
    const [updateTypes, setUpdateTypes] = useState<UpdateType[]>([] as UpdateType[]);
    const [specificUpdates, setSpecificUpdates] = useState({updates: []} as SpecificUpdate);
    const [currentFetchTimes, setCurrentFetchTimes] = useState({});
    const [lastFetchTimes, setLastFetchTimes] = useState({});
    const [activeUpdates, setActiveUpdates] = useState([] as UpdateType[]);
    const [activeSpecificUpdates, setActiveSpecificUpdates] = useState({updates: []} as SpecificUpdate);
    const [filters, setFilters] = useState<Filters>(storedFilters ? JSON.parse(storedFilters) as Filters : {
        forSale: false,
        offerings: false,
        marketMyArt: false,
        marketMyClaims: false,
        marketMyOffers: false,
        auctionMyArt: false,
        auctionMyClaims: false,
        auctionMyBids: false,
        forced: false,
        activeVote: true,
        created: false,
        owned: true,
        hidden: false
    })

    const toggleFilter = (filter: keyof Filters) => {
        setFilters((prevFilters) => ({...prevFilters, [filter]: !prevFilters[filter]}))
    }

    // Connect MetaMask
    const connectWallet = async () => {
        if (!isConnected) return window.alert('Please connect with a wallet !')

        // @ts-ignore
        const provider = new ethers.BrowserProvider(walletProvider)
        const network = await provider.getNetwork()
        console.log("Current Network " + network.chainId)

        const getAccounts = async () => {
            // Request connection to metamask's user ethereum accounts
            // @ts-ignore
            await window.ethereum.request({
                method: 'eth_requestAccounts',
            }).then(handleAccountsChanged)
                .catch((err: { code: number; }) => {
                    if (err.code === 4001) {
                        console.log('Please connect to MetaMask.');
                    } else {
                        console.error(err);
                    }
                })
        }

        const handleAccountsChanged = (accounts: string | any[]) => {
            if (!accounts || accounts.length === 0) {
                setLogIn(false);
                console.log('Please connect to MetaMask.');
            } else if (accounts[0] !== currentAccount) {
                const account = ethers.getAddress(accounts[0]);
                setCurrentAccount("");
                setCurrentAccount(account);
                setLogIn(true);
            }
        }

        if (network.chainId !== BigInt(943)) {
            try {
                // @ts-ignore
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId: "0x3AF"}]
                }).then(getAccounts)
                    .catch((err: { code: number; }) => {
                        if (err.code === 4001) {
                            console.log('Please connect to MetaMask.');
                        } else {
                            console.error(err);
                        }
                    });
            } catch (err) {
                if (err.code === 4902) {
                    // @ts-ignore
                    window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [{
                            chainId: "0x3AF",
                            rpcUrls: ["https://rpc.v4.testnet.pulsechain.com"],
                            chainName: "Pulsechain v4 test network",
                            nativeCurrency: {
                                name: "TPLS",
                                symbol: "TPLS",
                                decimals: 18
                            },
                            blockExplorerUrls: ["https://scan.v4.testnet.pulsechain.com"]
                        }]
                    }).then(getAccounts)
                        .catch((err: { code: number; }) => {
                            if (err.code === 4001) {
                                console.log('Please connect to MetaMask.');
                            } else {
                                console.error(err);
                            }
                        })
                }
            }
        } else {
            await getAccounts()
        }
    };

    const disconnectWallet = async () => {
        setCurrentAccount('');
        setTokenAmount('-');
        setTotalClaimPower('-');
        setLogIn(false);
        // Check if browser have installed metamask
        // @ts-ignore
        if (!window.ethereum) return window.alert('Please install MetaMask !');
        // @ts-ignore
        await window.ethereum.request({
            method: "wallet_requestPermissions",
            params: [
                {
                    eth_accounts: {}
                }
            ]
        });
    };

    // Check if connected before or not
    const checkWalletConnection = async () => {
        await connectWallet()
    }

    const loadTokenAmount = async () => {
        if (walletProvider) {
            // @ts-ignore
            const provider = new ethers.BrowserProvider(walletProvider)
            const signer = await provider.getSigner();
            const signerAddress = await signer.getAddress();
            try {
                const contract = new ethers.Contract(tokenContractAddress, genericErc20Abi, signer);
                const balance = await contract.balanceOf(signerAddress);
                const balanceInEther = ethers.formatEther(balance);
                const formattedBalance = parseFloat(balanceInEther).toFixed(2);
                setTokenAmount(formattedBalance);
            } catch (error) {
                setTokenAmount("");
                const network = await provider.getNetwork()
                console.log("Token not found on network " + network.chainId)
                console.error('Error fetching token:', error);
            }
        }
    }

    const loadNoNukeAmount = async () => {
        if (walletProvider) {
            // @ts-ignore
//            const provider = new ethers.providers.Web3Provider(walletProvider);
            const provider = new ethers.BrowserProvider(walletProvider)
//            const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
            const signer = await provider.getSigner();
            const signerAddress = await signer.getAddress();
            try {
                const contract = new ethers.Contract(noNukesContractAddress, genericErc20Abi, signer);
                const balance = await contract.balanceOf(signerAddress);
                const balanceInEther = ethers.formatUnits(balance, 8);
                const formattedBalance = parseFloat(balanceInEther).toFixed(2);
                setNoNukeAmount(formattedBalance);
            } catch (e) {
                const network = await provider.getNetwork()
                console.log("Token not found on network " + network.chainId)
            }
        }
    };

    const loadPulseAmount = async () => {
        if (walletProvider) {
            // @ts-ignore
//            const provider = new ethers.providers.Web3Provider(walletProvider);
            const provider = new ethers.BrowserProvider(walletProvider)
//            const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

            const signer = await provider.getSigner();
            const signerAddress = await signer.getAddress();
            const balance = await provider.getBalance(signerAddress);
            const balanceInEther = ethers.formatEther(balance);
            const formattedBalance = parseFloat(balanceInEther).toFixed(2);
            setPulseAmount(formattedBalance);
        }
    };

    // Convert the balance from wei to ether (or the base unit of the native token)


    const handleUpdateUser = async () => {
        const userPayload = {
            account: currentAccount,
            signature: accountSignature,
            walletAddress: currentAccount,
            crazyBalance: tokenAmount,
        };
        if (accountSignature && currentAccount && tokenAmount) {
            await axios.post('/users/', userPayload);
        }
    };

    // Create NFT (signer side)
    const createNFT = async (url: string, unformattedPrice: string, id: number, resell: boolean) => {
        // Interact contract as signer
        // @ts-ignore
//        const provider = new ethers.providers.Web3Provider(walletProvider);
        const provider = new ethers.BrowserProvider(walletProvider)
//        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const contract = fetchContract(signer);

        // Parse price number so that the machine can understand
        const price = ethers.parseUnits(unformattedPrice, 'ether');
        const listingPrice = await contract.getListingPrice();

        // Pay listing fee to the market owner (value = msg.value)
        const sellMarketItem = resell
            ? await contract.resellItem(id, price, {
                value: listingPrice.toString(),
            })
            : await contract.createToken(url, price, {
                value: listingPrice.toString(),
            });

        await sellMarketItem.wait();
    };

    const endAuction = async (tokenId: number) => {
        try {
            await axios.post('/auction-end/', {
                account: currentAccount,
                signature: accountSignature,
                tokenId: tokenId
            });
        } catch (error) {
            console.error('Error ending auction:', error);
        }
    };

    const faucetHandler = async () => {
        try {
            const response = await axios.get('/faucet/' + currentAccount);
            console.log('Faucet: ' + JSON.stringify(response))
        } catch (error) {
            console.error('Error requesting faucet', error);
        }
    }

    const fetchAdminConfiguration = async () => {
        try {
            const response = await axios.get('/admin/');
            const admin: Admin = response.data;
            setAdminConfiguration(admin);
        } catch (error) {
            console.error('Error fetching admin configuration:', error);
        }
    };

    const fetchAllArt = async (forced: boolean) => {
        if (forced || !artDataList) {
            try {
                const response = await axios.get('/art/');
                setArtDataList(response.data);
            } catch (error) {
                console.error('Error fetching all art:', error);
            }
        }
    };

    const fetchCategories = async () => {
        if (categories?.length) return
        try {
            const response = await axios.get('/categories/');
            setCategories(response.data)
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchImage = async (imageId: number) => {
        const cachedImage = imageList.find(image => image.id === imageId);
        if (cachedImage) {
            return cachedImage;
        }
        try {
            const response = await axios.get('/image/' + imageId);
            const fetchImage = response.data as Image;
            setImageList(prevList => [...prevList, fetchImage]);
            return fetchImage;
        } catch (error) {
            console.error('Error fetch image:', error);
            return {} as Image
        }
    };

    const getCachedImage = (imageId: number): Image | undefined => {
        return imageList.find(image => image.id === imageId);
    };

    const fetchTokenTransactions = async (tokenId: number | string, forced: boolean) => {
        if (forced || !transactionTokenArray[tokenId]) {
            try {
                const response = await axios.get(`/transactions/${tokenId}`);

                setTransactionTokenArray(prevState => {
                    const newState = [...prevState]
                    newState[tokenId as number] = response.data as Transaction[]
                    return newState
                })
            } catch (error) {
                console.error('Error fetching all transactions:', error);
            }
        }
    };

    const fetchUserTransactions = async (user: string | number, forced: boolean) => {
        if (user) {
            if (forced || !transactionUserArray[user]) {
                try {
                    const response = await axios.get(`/transactions-user/${user}`);
                    setTransactionUserArray(prevState => {
                        const newState = [...prevState]
                        newState[user as string] = response.data as Transaction[]
                        return newState
                    })
                } catch (error) {
                    console.error('Error fetching all transactions:', error);
                }
            }
        }
    }

    const acceptBid = async (artData: ArtData) => {
        try {
            const message = `Accepting bid for token ID ${artData.art.tokenId}`
            const signature = await signMessage(message)

            await axios.post('/accept-bid/', {
                tokenId: artData.art.tokenId,
                currentAccount: currentAccount,
                signature: signature,
                message: message
            })
        } catch (error) {
            console.error('Error accepting highest bid:', error);
        }
    }

    const signMessage = async (message: string) => {
        // @ts-ignore
        const signature = await window?.ethereum?.request({
            method: 'personal_sign',
            params: [message, currentAccount],
        });
        return signature as string
    }

    const acceptOffer = async (artData: ArtData) => {
        try {
            const message = `Accepting offer for token ID ${artData.art.tokenId}`
            const signature = await signMessage(message)

            const transferResult = await transferNft(artData, currentAccount)
            if (transferResult) {
                await axios.post('/accept-offer/', {
                    tokenId: artData.art.tokenId,
                    currentAccount: currentAccount,
                    signature: signature,
                    message: message
                })
            } else {
                console.error('Error sending NFT to custodial wallet and accepting highest offer:')
            }
        } catch (error) {
            console.error('Error accepting highest offer:', error)
        }
    }

    const fetchAllAuctions = async (forced: boolean) => {
        if (forced || !auctionDataList) {
            try {
                const response = await axios.get('/auctions/');
                const artData: ArtData[] = response.data;
                setAuctionDataList(artData);
            } catch (error) {
                console.error('Error fetching all auctions:', error);
            }
        }
    };

    const fetchVotes = async (forced?: boolean) => {
        if (currentAccount) {
            if (forced || !votedDataList) {
                try {
                    const response = await axios.get('/votes/' + currentAccount);
                    const voteData: VoteData[] = response.data;
                    setVotedDataList(voteData.reverse());
                } catch (error) {
                    console.error('Error fetching user votes:', error);
                }
            }
        } else {
            setVotedDataList([])
        }
    };

    const fetchClaims = async (forced?: boolean) => {
        if (currentAccount) {
            if (forced || !claimedDataList) {
                try {
                    const response = await axios.get('/claims/' + currentAccount);
                    const claimData: ClaimData[] = response.data;
                    setClaimedDataList(claimData.reverse());
                } catch (error) {
                    console.error('Error fetching user votes:', error);
                }
            }
        } else {
            setClaimedDataList([])
        }
    };

    // Bid on NFTs
    const bidHandler = async (artData: ArtData, bidAmount: number, previousBid = 0) => {
        setLoading(true);
        try {
            // Interact contract as signer
            // @ts-ignore
//        const provider = new ethers.providers.Web3Provider(walletProvider);
            const provider = new ethers.BrowserProvider(walletProvider)
//        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
            const signer = await provider.getSigner();
            const erc20Contract = new ethers.Contract(tokenContractAddress, genericErc20Abi, signer);

            // Calculate the price in ERC20 tokens and approve the transfer
            const bidIncrease = ethers.parseUnits((bidAmount - previousBid).toFixed(2), 'ether');
            // Assuming the bid is in ether units
            await erc20Contract.approve(tokenContractAddress, bidIncrease);

            // Transfer the ERC20 tokens to the marketplace custodial wallet
            const transferTx = await erc20Contract.transfer(custodial, bidIncrease);
            const receipt = await transferTx.wait(1);

            // Once the token transfer is successful, notify the server to transfer the NFT.
            // Define a function to attempt sending the receipt to your server
            const attemptToSendReceipt = async (attempt = 1) => {
                try {
                    await axios.post('/bid-on-nft/', {
                        account: currentAccount,
                        signature: accountSignature,
                        amount: bidAmount,
                        bidIncrease: (bidAmount - previousBid).toFixed(2),
                        sender: await signer.getAddress(),
                        receiver: custodial,
                        artId: artData.art.id,
                        receipt: JSON.stringify(receipt)
                    });
                } catch (error) {
                    if (attempt <= 5) { // Retry up to 5 times
                        console.log(`Attempt ${attempt}: Retrying to send receipt...`);
                        setTimeout(() => attemptToSendReceipt(attempt + 1), 2000); // Wait 2 seconds before retrying
                    } else {
                        console.error('Failed to send receipt to the server after several attempts.');
                    }
                }
            }

            await attemptToSendReceipt();
        } catch (error) {
            console.error('Error during bid handling:', error)
        } finally {
            setLoading(false);
        }
    };

    const offerHandler = async (artData: ArtData, amount: number, expiration: number) => {
        setLoading(true);
        // Interact contract as signer
        // @ts-ignore
        const provider = new ethers.BrowserProvider(walletProvider)
        const signer = await provider.getSigner();
        const erc20Contract = new ethers.Contract(tokenContractAddress, genericErc20Abi, signer);

        // Calculate the price in ERC20 tokens and approve the transfer
        const price = ethers.parseUnits(amount > 0 ? amount.toString() : "0", 'ether')
        await erc20Contract.approve(tokenContractAddress, price)

        // Transfer the ERC20 tokens to the NFT creator
        const transferTx = await erc20Contract.transfer(custodial, price);
        const receipt = await transferTx.wait(1);

        // Once the token transfer is successful, notify the server to transfer the NFT.
        // Define a function to attempt sending the receipt to your server
        const attemptToSendReceipt = async (attempt = 1) => {
            try {
                await axios.post('/offer-on-nft/', {
                    account: currentAccount,
                    signature: accountSignature,
                    sender: await signer.getAddress(),
                    receiver: custodial,
                    artId: artData.art.id,
                    amount: amount > 0 ? amount.toString() : "0",
                    expirationInHours: expiration,
                    receipt: JSON.stringify(receipt)
                });
            } catch (error) {
                if (attempt <= 5) { // Retry up to 5 times
                    console.log(`Attempt ${attempt}: Retrying to send receipt...`);
                    setTimeout(() => attemptToSendReceipt(attempt + 1), 2000); // Wait 2 seconds before retrying
                } else {
                    console.error('Failed to send receipt to the server after several attempts.');
                }
            }
        }

        await attemptToSendReceipt();
        setLoading(false);
    }

    const sendTransaction = async (amount = "-1", type: TransactionType, status: TransactionStatus,
                                   expiration = new Date(Date.now()), senderId: number,
                                   sender: string, receiverId: number, receiver: string,
                                   artId: number, owner: string, receipt: string, activity: TransactionActivity): Promise<string> => {
        const errorMessage = 'Failed to send transaction to the server after several attempts.'
        const attemptToSend = async (attempt = 1) => {
            try {
                await axios.post('/transaction/', {
                    account: currentAccount,
                    signature: accountSignature,
                    amount: amount,
                    type: type,
                    status: status,
                    expiration: expiration,
                    activity: activity,
                    senderId: senderId,
                    sender: sender,
                    receiverId: receiverId,
                    receiver: receiver,
                    artId: artId,
                    owner: owner,
                    receipt: receipt,
                });
                return "";
            } catch (error) {
                if (attempt <= 5) { // Retry up to 5 times
                    console.log(`Attempt ${attempt}: Retrying to send ...`);
                    setTimeout(() => attemptToSend(attempt + 1), 2000); // Wait 2 seconds before retrying
                } else {
                    console.error(errorMessage)
                    return errorMessage
                }
            }
            return errorMessage
        }
        return await attemptToSend()
    }

    const transferNft = async (artData: ArtData, sellerAddress: string): Promise<string> => {
        const errorMessage = "Transfer NFT error: "
        const errorMessage2 = "Transfer receipt indicates a failed transfer"
        try {
            // @ts-ignore
            const provider = new ethers.BrowserProvider(walletProvider)
            const signer = await provider.getSigner();
            const network = await provider.getNetwork()
            const nftAddress = blockchainConfig[Number(network.chainId)].nft.address
            const nft = new ethers.Contract(nftAddress, NFT, signer)
            await nft.approve(nftAddress, artData.art.tokenId)

            const transaction = await nft['safeTransferFrom(address,address,uint256)'](sellerAddress, custodial, artData.art.tokenId)
            const receipt = await transaction.wait()


            if (!receipt.status) {
                console.log(errorMessage2)
                await sendTransaction(undefined, TransactionType.TRANSFER_NFT, TransactionStatus.ERROR,
                    undefined, -1, sellerAddress,
                    -1, custodial,
                    artData.art.id, artData.currentOwner, JSON.stringify(receipt), TransactionActivity.INACTIVE)
                return errorMessage2
            }

            await sendTransaction(undefined, TransactionType.TRANSFER_NFT, TransactionStatus.TRANSFERRED,
                undefined, -1, sellerAddress,
                -1, custodial,
                artData.art.id, artData.currentOwner, JSON.stringify(receipt), TransactionActivity.INACTIVE)

            return ""
        } catch (error) {
            const transferResult = errorMessage + error
            await sendTransaction(undefined, TransactionType.TRANSFER_NFT, TransactionStatus.ERROR,
                undefined, -1, currentAccount,
                -1, custodial,
                artData.art.id, artData.currentOwner, JSON.stringify(transferResult), TransactionActivity.INACTIVE)
            window.alert(transferResult)
            console.log(transferResult)
            return error.message
        }
    }

    const saleHandler = async (artData: ArtData, amount: string, expiration: number) => {
        setLoading(true);

        const attemptToSend = async (attempt = 1) => {
            try {
                await axios.post('/sale-nft/', {
                    account: currentAccount,
                    signature: accountSignature,
                    sender: currentAccount,
                    receiver: custodial,
                    artId: artData.art.id,
                    amount: amount,
                    expirationInHours: expiration
                });
            } catch (error) {
                if (attempt <= 5) { // Retry up to 5 times
                    console.log(`Attempt ${attempt}: Retrying to send ...`);
                    setTimeout(() => attemptToSend(attempt + 1), 2000); // Wait 2 seconds before retrying
                } else {
                    console.error('Failed to send to the server after several attempts.');
                }
            }
        }
        if (artData.state === ArtState.FOR_SALE) {
            await attemptToSend();
        } else if (!(amount === "" || amount === "-1")) {
            const transferResult = await transferNft(artData, currentAccount)
            if (!transferResult) {
                await attemptToSend();
            }
        }
        setLoading(false);
    }

    const remove = async (artId: number) => {
        await axios.post('/remove/', {
            account: currentAccount,
            signature: accountSignature,
            artId: artId,
        });
    };

    // Fetch all NFTs users have listed or owned (seller/owner: signer, signer side)
    const fetchPortfolio = async (forced?: boolean): Promise<void> => {
        if (currentAccount) {
            if (forced || !portfolioData) {
                const response = await axios.get('/portfolio/' + currentAccount);
                setPortfolioData(response.data)
            }
        } else {
            setPortfolioData([])
        }
    }

    const fetchProposals = async (forced?: boolean): Promise<void> => {
        if (forced || !proposalData) {
            const response = await axios.get('/proposals/');
            setProposalData(response.data)
        }
    }

    const toggleHideArt = async (artData: ArtData): Promise<void> => {
        if (currentAccount) {
            try {
                const response = await axios.post('/toggle-hide-art/', {
                    account: currentAccount,
                    signature: accountSignature,
                    artId: artData.art.id
                });
                console.log(response.data);
            } catch (error) {
                console.error('Error toggling art state:', error);
            }
        } else {
            console.warn('No current account available');
        }
    };

    const getTokenomics = (artData: ArtData): Tokenomics => {
        switch (artData.state) {
            case ArtState.CREATED:
            case ArtState.FORCED_AUCTION:
                const tokenomicsForcedAuction = artData.art.tokenomicsForcedAuction
                return {
                    artState: artData.state,
                    owner: 0,
                    fee: tokenomicsForcedAuction.fee,
                    burn: tokenomicsForcedAuction.burn,
                    burnToken: tokenomicsForcedAuction.burnToken,
                    creator: tokenomicsForcedAuction.creator,
                    votes: tokenomicsForcedAuction.votes,
                    claims: tokenomicsForcedAuction.claims,
                    airdrop: tokenomicsForcedAuction.airdrop,
                    marketing: tokenomicsForcedAuction.marketing
                }
            case ArtState.NOT_FOR_SALE:
            case ArtState.AUCTION:
            case ArtState.FOR_SALE:
                const tokenomicsAuction = artData.art.tokenomicsAuction
                return {
                    artState: artData.state,
                    owner: tokenomicsAuction.owner,
                    burn: tokenomicsAuction.burn,
                    burnToken: tokenomicsAuction.burnToken,
                    airdrop: tokenomicsAuction.airdrop,
                    claims: tokenomicsAuction.claims,
                    creator: tokenomicsAuction.creator,
                    fee: tokenomicsAuction.fee,
                    marketing: tokenomicsAuction.marketing,
                    votes: 0
                }
            default:
                return {} as Tokenomics
        }
    }

    const voteHandler = async (artData: ArtData, voteValue: number): Promise<void> => {
        const attemptToSendVote = async (attempt = 1) => {
            try {
                const response = await axios.post('/voting/', {
                    account: currentAccount,
                    signature: accountSignature,
                    userId: currentAccount,
                    artId: artData.art.id,
                    amount: voteValue,
                    tokenId: 0
                });
                const voteData = response.data;
                console.log('Vote submitted: ' + JSON.stringify(voteData));
            } catch (error) {
                if (attempt <= 5) { // Retry up to 5 times
                    console.log(`Attempt ${attempt}: Retrying to send vote...`);
                    setTimeout(() => attemptToSendVote(attempt + 1), 2000); // Wait 2 seconds before retrying
                } else {
                    console.error('Error submitting vote:', error);
                }
            }
        }
        await attemptToSendVote()
    };

    const claimHandler = async (castingArtId: number, artData: ArtData, claimValue: number): Promise<void> => {
        const attemptToSendClaim = async (attempt = 1) => {
            try {
                const response = await axios.post('/claiming/', {
                    account: currentAccount,
                    signature: accountSignature,
                    userAccount: currentAccount,
                    targetArtId: artData.art.id,
                    amount: claimValue,
                    castingArtId: castingArtId
                });
                const claimData = response.data;
                console.log('Claim submitted: ' + JSON.stringify(claimData));
            } catch (error) {
                if (attempt <= 5) { // Retry up to 5 times
                    console.log(`Attempt ${attempt}: Retrying to send claim...`);
                    setTimeout(() => attemptToSendClaim(attempt + 1), 2000); // Wait 2 seconds before retrying
                } else {
                    console.error('Error submitting vote:', error);
                }
            }
        }
        await attemptToSendClaim();
    };

    const proposeHandler = async (artData: ArtData) => {

        const artId = {
            id: artData.art.id.toString()
        }

        try {
            const response = await axios.post('/propose-mint/', {
                account: currentAccount,
                signature: accountSignature,
                id: artId
            });
            console.log("Art stored at " + response.data);
        } catch (error) {
            window.alert(error)
            console.error('Error proposing mint:', error);
        }
    }

    const fetchUpdate = async (update: UpdateType, forced = true): Promise<void> => {
        console.log("Fetching: ", forced + " " + update)
        switch (update) {
            case UpdateType.VOTES: {
                fetchVotes(forced).then()
                break
            }
            case UpdateType.PROPOSALS: {
                fetchProposals(forced).then()
                break
            }
            case UpdateType.CLAIMS: {
                fetchClaims(forced).then()
                break
            }
            case UpdateType.ADMIN: {
                fetchAdminConfiguration().then()
                break
            }
            case UpdateType.AUCTIONS: {
                fetchAllAuctions(forced).then()
                break
            }
            case UpdateType.PORTFOLIO: {
                fetchPortfolio(forced).then()
                break
            }
            case UpdateType.MARKETS: {
                fetchAllArt(forced).then()
                break
            }
            default:
                return
        }
        setLastFetchTimes((previous) => ({...previous, [update]: new Date()}))
    }

    const fetchSpecificUpdate = async (updateSpecific: UpdateSpecific, forced = true): Promise<void> => {
        console.log("Fetching Specific Update: ", forced + " " + JSON.stringify(updateSpecific))
        switch (updateSpecific.type) {
            case UpdateSpecificType.TRANSACTION_USER: {
                fetchUserTransactions(updateSpecific.id, forced).then()
                break
            }
            case UpdateSpecificType.TRANSACTION_TOKEN: {
                fetchTokenTransactions(updateSpecific.id, forced).then()
                break
            }
            default:
                return
        }
        setLastFetchTimes((previous) => ({...previous, [JSON.stringify(updateSpecific)]: new Date()}))
    }

    const reconnectWebSocket = () => {
        setTimeout(() => {
            console.log('Attempting to reconnect WebSocket...');
            setSocket(null)
            ws = new WebSocket(serverConfig.chatURL);
        }, 5000)
    }

    useEffect(() => {
        updateTypes.forEach(update => {
            if (update && activeUpdates.includes(update)) {
                fetchUpdate(update).then()
            }
            setCurrentFetchTimes((previousTimes) => ({
                ...previousTimes,
                [update]: new Date(),
            }))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTypes])

    useEffect(() => {
        specificUpdates.updates.forEach(updateSpecific => {
            if (updateSpecific && activeSpecificUpdates.updates.some(activeUpdate => _.isEqual(activeUpdate, updateSpecific))) {
                fetchSpecificUpdate(updateSpecific).then()
            }
            setCurrentFetchTimes((previousTimes) => ({
                ...previousTimes,
                [JSON.stringify(updateSpecific)]: new Date(),
            }))
        })
        // Always update client data as it's usually needed
        loadTokenAmount().then()
        fetchClaims().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specificUpdates])

    useEffect(() => {
        activeUpdates.forEach(update => {
            const lastFetchTime = lastFetchTimes[update]
            const currentFetchTime = currentFetchTimes[update]
            if ((!lastFetchTime || !currentFetchTime) || lastFetchTime < currentFetchTime) {
                if (!currentFetchTime) {
                    setCurrentFetchTimes((previousTimes) => ({
                        ...previousTimes,
                        [update]: new Date(),
                    }))
                }
                fetchUpdate(update, true).then()
            } else {
                fetchUpdate(update, false).then()
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeUpdates]);

    useEffect(() => {
        activeSpecificUpdates.updates.forEach(updateSpecific => {
            const updateSting = JSON.stringify(updateSpecific)
            const lastFetchTime = lastFetchTimes[updateSting]
            const currentFetchTime = currentFetchTimes[updateSting]
            if ((!lastFetchTime && !currentFetchTime) || lastFetchTime < currentFetchTime) {
                if (!currentFetchTime) {
                    setCurrentFetchTimes((previousTimes) => ({
                        ...previousTimes,
                        [JSON.stringify(updateSpecific)]: new Date(),
                    }))
                }
                fetchSpecificUpdate(updateSpecific, true).then()
            } else {
                fetchSpecificUpdate(updateSpecific, false).then()
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSpecificUpdates]);

    useEffect(() => {
        ws = new WebSocket(serverConfig.chatURL);

        const pingInterval = setInterval(() => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({type: "ping"}));
            } else {
                reconnectWebSocket()
            }
        }, 30000);

        ws.onopen = () => {
            console.log('Connected to WebSocket server');
            ws.send(JSON.stringify({type: "getAllMessages"}));
            ws.send(JSON.stringify({type: "getPulsePrice"}));
            ws.send(JSON.stringify({type: "getCrazyPrice"}));
            setSocket(ws);
        };

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'connection') {
//                setSocketId(message.data.socketID);
            }
            switch (message.type) {
                case "pong":
                    break
                case "updateTypesResponse":
                    setUpdateTypes(message.data);
                    break
                case "specificUpdateResponse":
                    setSpecificUpdates((prevState) => {
                        const newUpdates = message.data.updates.filter(
                            (newUpdate: UpdateSpecific) =>
                                !prevState.updates.some(
                                    (existingUpdate) =>
                                        existingUpdate.id === newUpdate.id &&
                                        existingUpdate.type === newUpdate.type
                                )
                        );
                        return {
                            updates: [...prevState.updates, ...newUpdates],
                        };
                    });
                    break
                case "messagesResponse":
                    setMessages(message.data);
                    break
                case "messageResponse":
                    setMessages((prevMessages) => {
                        const exists = prevMessages.some((msg) => msg.id === message.data.id);
                        if (!exists) {
                            return [...prevMessages, message.data]
                        }
                        return prevMessages
                    });
                    break
                case "typingResponse":
                    setTypingStatus(message.data);
                    break
                case "pulsePrice":
                    setPulsePrice(message.data);
                    break
                case "crazyPrice":
                    setCrazyPrice(message.data);
                    break
                default:
                    console.log('Unknown message type:', message.type);
                    break
            }
        };

        ws.onclose = () => {
            console.log('Disconnected from WebSocket server');
//            clearInterval(pingInterval)
            setSocket(null);
//            reconnectWebSocket()
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        // Cleanup on unmount
        return () => {
            if (ws && ws.readyState === WebSocket.OPEN) {
                console.log("Disconnect chat");
                ws.close();
            }
            clearInterval(pingInterval)
        };
    }, []);

    useEffect(() => {
        fetchCategories().catch(console.error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Auto connect available accounts on load
    useEffect(() => {
        if (accountSignature?.length) {
            fetchPortfolio(true).catch(console.error)
            fetchVotes(true).catch(console.error)
            fetchClaims(true).catch(console.error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountSignature]);

    const renewAccountSignature = async () => {
        try {
            const message = `YourToken.art account signature for ${currentAccount},${Date.now()} which will expire in 48h`
            signMessage(message).then(signature => {
                axios.post('/renew-account-signature/', {
                    signature: signature,
                    message: message,
                    account: currentAccount
                }).then(result => {
                    if (result.status === 200) {
                        localStorage.setItem("account", currentAccount)
                        localStorage.setItem(`account_signature_${currentAccount}`, signature)
                        setAccountSignature(signature)
                    } else {
                        localStorage.removeItem(`account_signature_${currentAccount}`)
                    }
                })
            })
        } catch (error) {
            localStorage.removeItem(`account_signature_${currentAccount}`)
            console.error('Error creating account signature', error);
        }
    }

    // Auto connect available accounts on load
    useEffect(() => {
        if (!currentAccount?.length) {
            setAccountSignature("")
            return
        }
        localStorage.setItem("account", currentAccount)

        const localSignature = localStorage.getItem(`account_signature_${currentAccount}`)

        if (localSignature && currentAccount?.length) {
            axios.post('/check-account-signature/', {
                account: currentAccount,
                signature: localSignature
            }).then(result => {
                if (result.status === 200 && result.data.valid) {
                    setAccountSignature(localSignature);
                } else {
                    localStorage.removeItem(`account_signature_${currentAccount}`)
                    renewAccountSignature().then()
                }
            })
        } else if (currentAccount?.length) {
            renewAccountSignature().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAccount]);

    useEffect(() => {
        handleUpdateUser().catch(console.error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAccount, tokenAmount]);

    useEffect(() => {
        fetchAdminConfiguration().catch(console.error)
    }, [artDataList]);

    useEffect(() => {
        setPortfolioClaimTable(generatePortfolioClaimTable(portfolioData))
    }, [portfolioData]);

    useEffect(() => {
        setClaimedAmountTable(generateClaimedAmountTable(claimedDataList))
    }, [claimedDataList]);

    useEffect(() => {
        if (!currentAccount || currentAccount === "") {
            setVotePower('-')
            setTotalClaimPower('-')
        } else {
            const totalVotedAmount: number = parseFloat(tokenAmount) - calcTotalVotedAmount(votedDataList);
            if (totalVotedAmount <= 0) {
                setVotePower('0')
            } else if (!totalVotedAmount) {
                setVotePower('-')
            } else {
                setVotePower(parseFloat(totalVotedAmount.toString()).toFixed(2))
            }

            const claimPower: number = Object.values(portfolioClaimTable).reduce((total, amount) => total + amount, 0);
            if ((claimPower && claimPower <= 0)) {
                setTotalClaimPower('0')
            } else if (!claimPower) {
                setTotalClaimPower('-')
            } else {
                setTotalClaimPower(parseFloat(claimPower.toString()).toFixed(2))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [votedDataList, portfolioClaimTable, tokenAmount, currentAccount]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            localStorage.setItem("filters", JSON.stringify(filters))
        }
    }, [filters])

    return (
        <Context.Provider
            value={{
                acceptBid: acceptBid,
                acceptOffer: acceptOffer,
                adminConfiguration,
                artDataList,
                auctionDataList,
                accountSignature,
                bidHandler,
                categories,
                checkWalletConnection,
                claimedAmountTable,
                claimedDataList,
                claimHandler,
                connectWallet,
                crazyPrice,
                createNFT,
                currentAccount,
                disconnectWallet,
                endAuction,
                faucetHandler,
                fetchImage,
                filters,
                getCachedImage,
                getTokenomics,
                hideContent,
                loadNoNukeAmount,
                loadPulseAmount,
                loadTokenAmount,
                loading,
                logIn,
                messages,
                noNukeAmount,
                offerHandler,
                portfolioClaimTable,
                portfolioData,
                proposeHandler,
                proposalData,
                pulseAmount,
                pulsePrice,
                remove,
                saleHandler,
                setActiveUpdates,
                setActiveSpecificUpdates,
                setHideContent,
                setLoading,
                socket,
                socketId,
                tokenAmount,
                toggleHideArt,
                toggleFilter,
                totalClaimPower,
                transactionUserArray,
                transactionTokenArray,
                typingStatus,
                voteHandler,
                votePower,
                votedDataList
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }}>
            {children}
        </Context.Provider>
    )
}

export default ContextProvider;
