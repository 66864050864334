export enum ArtState {
    CREATED = 'CREATED',
    NOT_FOR_SALE = 'NOT_FOR_SALE',
    FOR_SALE = 'FOR_SALE',
    FORCED_AUCTION = 'FORCED_AUCTION',
    AUCTION = 'AUCTION',
    HIDDEN = 'HIDDEN',
    BLOCKED = 'BLOCKED',
}

export enum DistributionType {
    AIRDROP = 'AIRDROP',
    BURN = 'BURN',
    CLAIM = 'CLAIM',
    CREATOR = 'CREATOR',
    FEE = 'FEE',
    MARKETING = 'MARKETING',
    OWNER = 'OWNER',
    VOTE = 'VOTE',
    UNDEFINED = 'UNDEFINED',
}

export enum DistributionStatus {
    PLANNED = 'PLANNED',
    EXECUTED = 'EXECUTED',
    EXECUTING = 'EXECUTING',
    ERROR = 'ERROR',
    DONE = 'DONE',
}

export enum Token {
    CRAZY = 'CRAZY',
    PLS = 'PLS',
    NO_NUKES = 'NO_NUKES',
}

export enum TransactionType {
    BUY = 'BUY',
    SELL = 'SELL',
    OFFER = 'OFFER',
    BID = 'BID',
    FOR_SALE = 'FOR_SALE',
    TRANSFER_NFT = 'TRANSFER_NFT',
    TRANSFER_TOKEN = 'TRANSFER_TOKEN',
    TRANSFER_NATIVE = 'TRANSFER_NATIVE',
    REIMBURS = 'REIMBURS',
}

export enum TransactionStatus {
    INITIATED = 'INITIATED',
    HANDLING = 'HANDLING',
    ERROR = 'ERROR',
    TRANSFERRED = 'TRANSFERRED',
}

export enum TransactionActivity {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ACCEPTED = 'ACCEPTED'
}
